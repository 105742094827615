// export const baseurl = "http://172.16.1.53:2063"; // kamal local
// export const baseurl = "http://172.16.1.247:2063"; // vishnu local
// export const baseurl = "http://172.16.6.130:2063"; // tanveer local
// export const baseurl = "https://newnode-banktoken.mobiloitte.io"; // staging

const baseurl = process.env.REACT_APP_BASE_URL; //live
// export const reCaptchaKey = "6Lcr0iwqAAAAAGHIC5QZd4wOgFpsSwSgffIba3JE";
export const reCaptchaKey = "6LfUy9UpAAAAAOem85goMJaUQYtdegN3jD9Ih-Z6";

const url = `${baseurl}/api/v1/admin`;
const url1 = `${baseurl}/api/v1`;
let wallet = `${baseurl}/api/v1/wallet`;

const ApiConfig = {
  login: `${url}/login`,
  googleAuth: `${url}/googleAuth`,
  verifyGoogleAuth: `${url}/verifyGoogleAuth`,
  verifyGoogleLogin: `${url}/verifyGoogleAuthLogin`,
  disableAuth: `${url}/disableAuth`,
  forgotPassword: `${url}/forgotPassword/`,
  verifyOtp: `${url}/verifyOTPForgotPassword`,
  resendOtp: `${url}/resendOTP`,
  resetPassword: `${url}/resetPassword`,
  walletAddress: `${url}/wallet-address`,

  myProfile: `${url}/getProfile`,
  editProfile: `${url}/editProfile`,
  changePassword: `${url}/changePassword`,
  setFNXPrice: `${url}/setFNXPrice`,
  getFNXprice: `${wallet}/getFNXprice`,

  //dashboard
  // /admin/stakingClaimListAdmin

  getDealHistory: `${url}/getDealHistory`,
  stakingClaimListAdmin: `${url}/stakingClaimListAdmin`,
  dashboardList: `${url}/dashboard`,
  userGraphList: `${url}/graphForUser`,
  graphForDeal: `${url}/graphForDeal`,
  allocateFunds: `${url}/reward-management/allocate-funds/`,
  distributeFunds: `${url}/reward-management/distribute-funds/`,

  //subAdmin
  subAdminList: `${url}/subAdminList`,
  editSubAdmin: `${url}/editPermission`,
  viewSubAdmin: `${url}/subAdminView`,
  addSubAdmin: `${url}/addSubAdmin`,
  subAdminXlsData: `${url}/sub-admin-management/sub-admin-list-xls/`,
  blockUnblockSubAdmin: `${url}/activeBlockUser`,
  deleteUser: `${url}/sub-admin-management/sub-admin-delete`,

  //userManagement
  userResetGoogleAuthenticator: `${url}/editUser2FA`, //user-reset-google-authenticator
  userManagementList: `${url}/userList`,
  userDetail: `${url}/viewUser`,
  blockUnblockUser: `${url}/activeBlockUser`,

  //kycManagement
  kycManagementList: `${url1}/kyc/kycList`,
  userWalletRequestList: `${url}/userWalletRequestList`,
  KycXlsData: `${url}/kyc-management-xls/`,
  approveKyc: `${url}/approveRejectWalletRequest`,

  //ticketManagement
  // ticketManagementList: `${url}/ticket-management/ticket-list/`,
  ticketReply: `${url}/replyContactUs`,
  ticketXlsData: `${url}/ticket-management/ticket-list-xls/`,
  ticketManagementList: `${url}/getContactUs`,

  walletManagementList: `${url1}/wallet/transactions`,

  createTransaction: `${url1}/wallet/createTransaction`,
  walletXlsData: `${url}/wallet-management/list-xls`,
  updateWallet: `${url}/wallet-management/update-wallet/`,
  withdraw: `${url1}/wallet/withdrawToken`,
  deposit: `${url}/wallet-management/deposit/`,
  rewardDeposit: `${url}/reward-management/wallet/deposit/`,

  //transaction
  transactionManagementList: `${url1}/wallet/transactions`,
  transactionXlsData: `${url}/transaction-management/list-xls`,

  //reward managemment
  rewardManagementList: `${url}/getDealHistory`,
  rewardXlsData: `${url}/reward-management/list-xls`,
  rewardManagementSetting: `${url}/getDeal`,
  editRewardData: `${url}/updateDeal`,

  //feeManagement
  feeManagementData: `${url}/fee-management`,
  updateFeeManagement: `${url}/fee-management`,

  //static content
  getStaticContent: `${url1}/static/staticList`,
  viewStaticContent: `${url1}/static/getStatic`,
  updateStaticContent: `${url1}/static/updateStatic`,

  // getPrivacyPolicyContent: `${url}/static-content-management/privacy-policy/`,
  // getAboutUsContent: `${url}/static-content-management/about-us/`,
  // editAboutUsContent: `${url}/static-content-management/about-us/`,
  // getTermsContent: `${url}/static-content-management/tearms-and-conditions/`,
  // editPrivacyPolicyContent: `${url}/static-content-management/privacy-policy/`,
  // editTermsAndConditionContent: `${url}/static-content-management/tearms-and-conditions/`,
  // getCookiesContent: `${url}/static-content-management/cookie-policy/`,
  // editCookiesContent: `${url}/static-content-management/cookie-policy/`,

  //faqManagement
  faqManagementList: `${url1}/static/faqList`,
  addFAQ: `${url1}/static/addFAQ`,
  editFAQ: `${url1}/static/editFAQ`,
  deleteFAQ: `${url1}/static/deleteFAQ`,

  //bannerManagement
  bannerManagementList: `${url}/static-content-management/banner/`,
  addBanner: `${url}/static-content-management/banner/`,

  //teamManagement
  teamList: `${url1}/team/teamList`,
  addteamMember: `${url1}/team/addTeam`,
  viewTeamMember: `${url1}/team/viewTeam`,
  editTeamMember: `${url1}/team/updateTeam`,
  deleteTeamMember: `${url1}/team/deleteTeam`,

  //blogManagement
  blogList: `${url1}/blog/getblogMediaList`,
  addBlog: `${url1}/blog/addblogMedia`,
  viewBlog: `${url1}/blog/viewBlog`,
  editBlog: `${url1}/blog/updateBlogMedia`,
  deleteBlog: `${url1}/blog/deleteblogMedia`,
  blockUnblockBlog: `${url1}/blog/activeBlockblogMedia`,

  //bank overview
  bankDetailsList: `${url}/getBankOverView`,
  editBankDetails: `${url}/editBankOverView`,
};
export default ApiConfig;
