import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { FineXimLogo } from "../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    zIndex: 2000,
  },
  loader: {
    height: 100,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      height: 100,
    },
  },
  progressBar: {
    height: "3px",
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className="displayColumn">
        <Box>
          <img className={classes.loader} src={FineXimLogo} alt="loader" />
        </Box>
      </Box>
    </Box>
  );
}
